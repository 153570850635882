<template>
  <a-modal
      :visible="visible"
      :footer="null"
      @cancel="closeModal"
      :width="650"
  >
    <div style="width: 600px; height: 400px">
      <video
          :src="previewSrc"
          id="media"
          autoplay
          style="
          width: calc(100% - 2px);
          height: calc(100% - 12px);
          border-radius: 10px;
        "
          controls="controls"
      />
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    previewSrc: {
      type: String
    }
  },

  data () {
    return {
    };
  },
  methods: {
    closeModal () {
      this.$emit('cancel');
    }
  }
};
</script>
