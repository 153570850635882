<template>
  <div>
    <div class="materialDetails">
      <div>
        <div class="mater_data_content">
          <div
              v-for="(item, index) in materialDetails"
              :key="index"
              :class="active === index ? 'item_box_active' : 'item_box'"
              @click="activtable(index, item)"
          >
            <div class="img_box">
              <img :src="item.cover_url" alt="素材封面"/>
            </div>
            <div class="tag_box">
              <p class="rid_text">
                车系:<span>{{ item.car_name }}</span>
              </p>
              <div class="media_tegs_content">
                <div
                    v-for="(k, i) in item.media_tags"
                    :key="i"
                    class="media_tegs_content"
                >
                  <span>{{ k.tag_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- 分页配置 -->
          <base-pagination
              :currentPage="paginationProps.current"
              :pageSize="paginationProps.page_size"
              :paginationSize="'small'"
              :total="paginationProps.total"
              @onChange="handleTableChange"
              @onShowSizeChange="handleTableChange"
          >
          </base-pagination>
        </div>
      </div>
      <div class="right_content">
        <div class="video_BOX" @click="showVideo">
          <p v-if="job_status == 'Queuing'" class="videoRed">
            视频生成排队中！请稍后～
          </p>
          <p v-if="job_status == 'Processing'" class="videoRed">
            视频处理中！请稍后～
          </p>
          <p v-if="job_status == 'Failed'" class="videoRed">
            视频生成失败!不支持播放
          </p>
          <img :src="tabItemData.cover_url" alt="选择预览"/>
          <span class="video_iocn">
            <a-icon style="font-size: 60px" type="play-circle"/>
          </span>
        </div>
        <div class="select_box">
          <div class="selectbtn">
            <a-select
                v-model="selectValue"
                :filterOption="filterOption"
                label-in-value
                placeholder="请选择车系"
                showSearch
                option-filter-prop="children"
                style="width: 300px"
                @change="changeSeries"
                :not-found-content="fetching ? undefined : null"
            >
              <a-spin v-if="fetching" slot="notFoundContent" size="small"/>
              <a-select-option
                  v-for="item in seriesSelectList"
                  :key="item.id"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="tags_box">
            <div class="add_btn">
              <a-button type="dashed" @click="showModel"> + 添加</a-button>
            </div>
            <div class="spanContent">
              <a-tag
                  v-for="tag in tagsList"
                  :key="tag.id"
                  :closable="true"
                  color="blue"
                  style="margin: 10px 12px"
                  @close="handleClose(tag.id)"
              >
                {{ tag.tag_name }}
              </a-tag>
              <!--  <span v-for="(s,i) in selectedMedia" :key="i">{{s.tag_name}}</span>-->
            </div>
          </div>
          <div class="savebtn">
            <a-button type="primary" @click="saveTags">保存</a-button>
          </div>
        </div>
      </div>
      <!--  // 弹框-->
      <a-modal
          v-model="visible"
          title="请选择标签"
          width="60%"
          @ok="selectedRowTag"
      >
        <div>
          <div class="input_search_btn">
            <a-input
                v-model="tag_name"
                placeholder="请输入标签名"
                style="width: 220px"
            />
            <a-button
                style="margin-left: 40px"
                type="primary"
                @click="searchTag"
            >查询
            </a-button
            >
          </div>
          <a-table
              :columns="columns"
              :data-source="tableList"
              :loading="tableLoading"
              :pagination="paginations"
              :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
              :rowKey="(record, index) => index"
              bordered
          >
          </a-table>
        </div>
      </a-modal>
    </div>

    <!--视频播放-->
    <video-modal
        :preview-src="preview_src"
        :visible="preview_visible"
        @cancel="handlePreviewCancel"
    />
  </div>
</template>

<script>
import api from "@/api/Edit.js";
import videoModal from "@/components/videoModal/index";

const columns = [
  {
    title: "分类",
    dataIndex: "category_name"
  },
  {
    title: "标签名",
    dataIndex: "tag_name"
  }
];
export default {
  name: "materialDetails",
  components: {
    videoModal
  },
  data () {
    return {
      paginationProps: {
        total: 0,
        page_size: 5,
        current: 1
      },
      preview_src: "",
      preview_visible: false,
      materialDetails: [],
      selectedRowKeys: [],
      tableList: [],
      selectedMedia: [],
      tagsList: [],
      seriesSelectList: [],
      tabItemData: {},
      tableLoading: false,
      active: 0,
      visible: false,
      columns,
      paginations: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `总共 ${ total } 条数据`
      },
      tag_name: "",
      car_id: "",
      filter: "",
      car_name: "",
      media_id: "",
      dealer_id: "",
      code: "",
      selectValue: "",
      job_status: "",
      scId: "",
      fetching: ""
    };
  },
  mounted () {
    this.scId = "";
    if (this.$route.query.code || this.$route.query.dealer_id) {
      this.code = this.$route.query.code;
      this.dealer_id = this.$route.query.dealer_id;
      this.getMaterialDetails(1, 5);
    }
  },
  methods: {
    getMaterialDetails (current, page_size) {
      let params = {
        page_no: current,
        page_size: page_size,
        code: this.code,
        dealer_id: this.dealer_id
      };
      this.tableLoading = true;
      api.getMaterialDetails(params).then((res) => {
        // this.active =  0
        this.tableLoading = false;
        if (res.code == 200) {
          this.materialDetails = res.data.list || [];
          let item = {};
          if (this.scId) {
            res.data.list.forEach((i) => {
              if (i.id == this.scId) {
                item = i;
              }
            });
          } else {
            res.data.list.map(() => {
              item = this.materialDetails[0];
            });
          }
          this.tabItemData = item;
          this.media_id = item.id;
          this.job_status = item.job_status;
          this.tagsList = item.media_tags || [];
          this.seriesSelectList = [];
          this.car_id = item.car_id;
          this.car_name = item.car_name;
          this.selectValue = {
            key: item.car_id,
            label: item.car_name
          };
          this.paginationProps.total = parseInt(res.data.total);
          this.paginationProps.current = res.data.page_num;
          this.getMmappingList(res.data.list[0].manufator_id);
        }
      });
    },
    showVideo () {
      if (this.job_status == "Queuing") {
        this.$message.error("视频生成排队中！暂无可以播放的视频");
        return;
      }
      if (this.job_status == "Processing") {
        this.$message.error("视频处理中！暂无可以播放的视频");
        return;
      }
      if (this.job_status == "Failed") {
        this.$message.error("抱歉！视频生成失败！暂无可以播放的视频");
        return;
      }
      this.preview_visible = true;
      this.preview_src = this.tabItemData.media_url;
    },
    handlePreviewCancel () {
      this.preview_visible = false;
      this.preview_src = "";
    },
    activtable (index, item) {
      this.active = index;
      this.tabItemData = item || {};
      this.media_id = this.tabItemData.id;
      this.job_status = this.tabItemData.job_status;
      this.tagsList = this.tabItemData.media_tags || [];
      this.seriesSelectList = [];
      this.selectValue = {
        key: this.tabItemData.car_id || "",
        label: this.tabItemData.car_name || ""
      };
      this.car_name = this.tabItemData.car_name;
      this.car_id = this.tabItemData.car_id;
      this.getMmappingList(item.manufator_id);
    },
    showModel () {
      this.selectedRowKeys = [];
      if (!this.media_id) {
        this.$message.error("请在左侧选择素材");
        return;
      }
      this.visible = true;
      this.tag_name = "";
      this.getTagList(1);
    },
    getTagList (current) {
      let params = {
        page_no: current,
        page_size: 10,
        tag_name: this.tag_name || ""
      };
      this.tableLoading = true;
      api.getTagList(params).then((res) => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.tableList = res.data.list || [];
          this.paginations.total = parseInt(res.data.total);
          this.paginations.current = res.data.page_num;
        }
      });
    },
    searchselectValue (value) {
      if (value) {
        this.selectValue = value;
        this.getMmappingList(1);
      }
    },
    getMmappingList (id) {
      let arr = id.split(",");
      this.fetching = true;
      let params = {
        manufatcor_ids: arr
      };
      api.getMmappingList(params).then((res) => {
        if (res.length) {
          this.seriesSelectList = res || [];
        }
      }).finally(() => {
        this.fetching = false;
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedMedia = selectedRows;
    },
    selectedRowTag () {
      if (!this.selectedMedia.length) {
        this.$message.error("请先选择标签");
        return;
      }
      let screenArr = this.tagsList.concat(this.selectedMedia);
      let newJson = [];
      for (let item1 of screenArr) {
        let flag = true;
        for (let item2 of newJson) {
          if (item1.id == item2.id) {
            flag = false;
          }
        }
        if (flag) {
          newJson.push(item1);
        }
      }
      this.tagsList = newJson;
      this.visible = false;
    },
    searchTag () {
      this.getTagList(1);
    },
    handleClose (removedTag) {
      this.tagsList.splice(
          this.tagsList.findIndex((item) => item.id === removedTag),
          1
      );
    },
    changeSeries (value) {
      this.car_name = value.label;
      this.car_id = value.key;
    },
    saveTags () {
      if (!this.car_id || !this.car_name) {
        this.$message.error("请选择车系");
        return;
      }
      if (!this.media_id) {
        this.$message.error("请先选择左侧素材");
        return;
      }
      if (this.tagsList.length === 0) {
        this.$message.error("请添加标签");
        return;
      }
      let ids = [];
      this.tagsList.map((item) => {
        ids.push(item.id);
      });
      let params = {
        car_id: this.car_id,
        car_name: this.car_name,
        media_id: this.media_id,
        tag_ids: ids
      };
      console.log(params);
      api.saveTelation(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.scId = this.media_id;
          this.getMaterialDetails(1, 5);
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    // 将输入的内容与显示的内容进行匹配
    filterOption (value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0;
    },
    handleTableChange (current, page_size) {
      this.getMaterialDetails(current, page_size);
    },
    filterOption (input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style lang="less" scoped>
.materialDetails {
  height: 100%;
  display: flex;
  align-items: center;
}

.mater_data_content {
  width: 400px;
  height: 600px;
  padding: 12px;
  overflow-y: auto;

  .item_box {
    width: 100%;
    height: 120px;
    background: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #eee;
  }

  .item_box_active {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: #ccc;
  }

  .img_box {
    width: 64px;
    height: 100px;
    //background: #d7d7d7;
    //border: 1px solid white;
    margin-right: 20px;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .tag_box {
    .rid_text {
      font-weight: bold;

      span {
        font-weight: 400;
      }
    }
  }

  .media_tegs_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      margin-right: 6px;
      margin-bottom: 6px;
      background: #3976f5;
      line-height: 22px;
      color: #fff;
      text-align: center;
      /* margin: 4px; */
      padding: 0 6px;
      border-radius: 4px;
    }
  }
}

.right_content {
  width: 800px;
  height: 460px;
  display: flex;
  //align-items: center;
  padding-left: 40px;
}

.video_BOX {
  width: 230px;
  height: 350px;
  position: relative;
  border: 1px solid #ccc;

  img {
    width: 100%;
    height: 100%;
  }

  .video_iocn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.tags_box {
  width: 390px;
  height: 288px;
  border: 1px solid #ccc;
  margin-left: 30px;
  padding: 20px;

  .add_btn {
    //display: inline-block;
    //width: 80px;
    //background: #ccc;
    //line-height: 30px;
    //text-align: center;
    //border: 1px solid #000;
    //cursor: pointer;
    //margin-bottom: 30px;
  }

  .spanContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      margin-right: 6px;
      margin-bottom: 6px;
      background: #3976f5;
      line-height: 22px;
      color: #fff;
      text-align: center;
      /* margin: 4px; */
      padding: 0 6px;
      border-radius: 4px;
    }
  }
}

.input_search_btn {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.selectbtn {
  padding-bottom: 20px;
  padding-left: 30px;
}

.savebtn {
  padding-top: 30px;
  padding-left: 80px;
}

.videoRed {
  font-size: 18px;
  color: red;
  font-weight: bold;
  position: absolute;
  top: -40px;
  left: 3px;
}
</style>
